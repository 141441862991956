var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-container"},[_c('div',{staticClass:"logo-tabs"},[_c('div',{staticClass:"menu-home",on:{"click":function () {
          _vm.$router.push({ path: '/index' });
        }}},[_c('i',{staticClass:"iconfont icon-logo-04 home-logo"})]),_c('div',[_c('el-menu',{staticClass:"el-menu-demo",attrs:{"default-active":_vm.activeIndex,"mode":"horizontal","background-color":"#fff","text-color":"#666","active-text-color":"#1890FF","router":""},on:{"select":_vm.handleSelect}},[_c('el-menu-item',{attrs:{"index":"/index"}},[_vm._v("后台管理")]),_c('el-menu-item',{attrs:{"index":"/header/cloud"}},[_vm._v("云文档")]),(_vm.getUserInfo.company_id == 23)?_c('el-menu-item',{attrs:{"index":"/quoted/quotes"}},[_vm._v("报价中心")]):_vm._e(),_c('el-menu-item',{attrs:{"index":"/assign-order/assign-list"}},[_vm._v("派单中心")])],1)],1)]),_c('div',{staticClass:"headerMsg-group"},[_c('div',{staticClass:"header-item"},[_c('el-dropdown',{attrs:{"trigger":"click"}},[_c('span',{staticClass:"el-dropdown-link"},[(_vm.userInfo.icon)?_c('span',[_c('img',{staticClass:"user-icon",attrs:{"src":_vm.$vuex.getters.getUserIcon}})]):_vm._e(),(!_vm.userInfo.icon)?_c('span',{staticStyle:{"vertical-align":"middle"}},[_c('i',{staticClass:"el-icon-user common-iconImg-MR"})]):_vm._e(),_vm._v(" 欢迎您，"+_vm._s(_vm.userInfo.nick_name)+" ")]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',[_c('div',{staticClass:"menuBtn",on:{"click":function($event){return _vm.winPush(("/personal-center?userId=" + (_vm.getUserInfo.user_id)))}}},[_vm._v(" 个人档案 ")])]),_c('el-dropdown-item',[_c('div',{staticClass:"menuBtn",on:{"click":function () {
                  _vm.routePush('/login', 'signOut');
                }}},[_vm._v(" 退出 ")])])],1)],1),_c('div',{staticClass:"roleHeader"},[_vm._v(_vm._s(_vm.userInfo.roles && _vm.userInfo.roles[0]))])],1),(_vm.isReadNum.c0 != 0)?_c('el-badge',{staticClass:"item",staticStyle:{"margin-right":"36px"},attrs:{"value":_vm.isReadNum.c0,"max":99}},[_c('div',{staticClass:"header-item",staticStyle:{"margin":"0"},on:{"click":function($event){return _vm.showDrawer(false)}}},[_c('img',{staticClass:"notice_icon",attrs:{"src":require("../../assets/images/notice_icon.png")}}),_vm._v(" 消息 ")])]):_vm._e(),(_vm.isReadNum.c0 == 0)?_c('div',{staticClass:"header-item",on:{"click":function($event){return _vm.showDrawer(false)}}},[_c('img',{staticClass:"notice_icon",attrs:{"src":require("../../assets/images/notice_icon.png")}}),_vm._v(" 消息 ")]):_vm._e(),_c('el-select',{staticClass:"common-screen-input",attrs:{"placeholder":"请选择"},on:{"change":function (val) {
          _vm.refreshPage(val);
        }},model:{value:(_vm.comIdDefult),callback:function ($$v) {_vm.comIdDefult=$$v},expression:"comIdDefult"}},_vm._l((_vm.companyList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('el-drawer',{attrs:{"title":_vm.isEditPassword ? '密码修改' : '通知列表',"visible":_vm.drawer,"append-to-body":true,"direction":"rtl","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.drawer=$event}}},[_c('div',{staticClass:"drawer-container"},[(_vm.isEditPassword)?_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm,"rules":_vm.rules,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"输入原密码","prop":"oldPassword"}},[_c('el-input',{staticClass:"common-screen-input",attrs:{"placeholder":"请输入原密码","show-password":""},model:{value:(_vm.ruleForm.oldPassword),callback:function ($$v) {_vm.$set(_vm.ruleForm, "oldPassword", $$v)},expression:"ruleForm.oldPassword"}})],1),_c('el-form-item',{attrs:{"label":"输入新密码","prop":"newPassword"}},[_c('el-input',{staticClass:"common-screen-input",attrs:{"placeholder":"请输入新密码","show-password":""},model:{value:(_vm.ruleForm.newPassword),callback:function ($$v) {_vm.$set(_vm.ruleForm, "newPassword", $$v)},expression:"ruleForm.newPassword"}})],1),_c('el-form-item',{attrs:{"label":"验证新密码","prop":"verifyPassword"}},[_c('el-input',{staticClass:"common-screen-input",attrs:{"placeholder":"请确认新密码","show-password":""},model:{value:(_vm.ruleForm.verifyPassword),callback:function ($$v) {_vm.$set(_vm.ruleForm, "verifyPassword", $$v)},expression:"ruleForm.verifyPassword"}})],1)],1):_vm._l((_vm.infoListData),function(item){return _c('div',{key:item.date},[_c('div',{staticClass:"message-item"},[_c('div',{staticClass:"message-date"},[_vm._v(_vm._s(item.date))]),_vm._l((item.list),function(itemList){return _c('div',{key:itemList.id,staticClass:"messageItem",on:{"click":function () {
                  _vm.messageRoute(itemList.id, itemList.type);
                }}},[_c('div',{staticClass:"message-tip"},[_vm._v(" "+_vm._s(itemList.type == 2 ? "提醒" : itemList.type == 1 ? "公告" : "预警")+" "),(itemList.is_read == 0)?_c('span',{staticClass:"isRead"}):_vm._e()]),(itemList.type == 1)?_c('span',[_vm._v(_vm._s(itemList.advice_title))]):(itemList.type == 2)?_c('span',[_vm._v(_vm._s(itemList.content))]):_c('span',[_vm._v(_vm._s(itemList.title))])])})],2)])})],2)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }